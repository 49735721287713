import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import { useDispatch, useSelector } from "react-redux"
import SEO from "../../components/seo"
import { Box, Flex } from "@chakra-ui/react"
import { batchActions } from "redux-batched-actions"

// 🚀 Transition
import TransitionButton from "../../components/Buttons/TransitionButton"
import Transition from "../../components/Animation/Transition"
import { moveCurrentPageOut } from "../../components/Animation/PageTransition"
import { moveCameraToLayer } from "../../components/Animation/CameraAnimation"

// 🎨 UI
import IntroPageWrapper from "../../components/Contents/IntroPageWrapper"
import SchwerpunkteSlider from "../../components/Slider/SchwerpunkteSlider"
import SchwerpunktIllustration from "../../components/Contents/SchwerpunktIllustration"

export default function Schwerpunkte({ data, location }) {
  const sendActionToStore = useDispatch()
  const post = data.allWpSchwerpunkt.nodes
  const settings = data.wpModeSettings.acf_schwerpunkt_settings
  const LANG = useSelector(state => state.currentLang)

  // 🚀 Transitions Settings
  const [exit, setExit] = useState(false)
  const defaultInAnimation = location?.state ? location.state.direction : "fade"
  const [AnimationDirection, setAnimationDirection] =
    useState(defaultInAnimation)

  // 🚀🧨 Transitions Redux Triggers
  const TRANSITION_INFO = useSelector(state => state.transition)
  useEffect(() => {
    if (TRANSITION_INFO.shouldExit && TRANSITION_INFO.direction) {
      setAnimationDirection(TRANSITION_INFO.direction)
      setExit(TRANSITION_INFO.shouldExit)
      sendActionToStore({
        type: `PageChangeTransition/ended`,
      })
    }
    return () => {
      setExit(true)
    }
  }, [TRANSITION_INFO, sendActionToStore])

  useEffect(() => {
    sendActionToStore(
      batchActions([
        {
          type: "change/locationsActive",
          payload: false,
        },
        {
          type: `change/projectScene`,
          payload: {
            topVisible: false,
            bottomVisible: false,
          },
        },
      ])
    )
  }, [])

  const [unmountSlider, setUnmountSlider] = useState(false)

  return (
    <>
      <SEO title={settings.textTitleMobilitatsschwerpunkt} />
      <Transition autoIn shouldExit={exit} direction={AnimationDirection}>
        <IntroPageWrapper
          isSlider
          rightContent={<SchwerpunktIllustration slides={post} />}
        >
          <Flex width="100%" align="center" direction="column">
            <TransitionButton
              primary
              text={
                data.projektzielSettings.acf_projektziel_settings
                  .textTitleButtonSchwerpunkte
              }
              link={LANG === "en" ? "/en/" : "/"}
              targetPageInAnimation="up"
              onClick={() => {
                moveCameraToLayer(sendActionToStore, "intro")
                moveCurrentPageOut(sendActionToStore, "down")
                setUnmountSlider(true)
              }}
              hasShadow
            />
            <Box height="24px"></Box>
            <SchwerpunkteSlider slides={post} settings={settings} />
          </Flex>
        </IntroPageWrapper>
      </Transition>
    </>
  )
}
export const query = graphql`
  query MobilitaetsschwerpunktCardQuery($lang: String!) {
    allWpSchwerpunkt(filter: { locale: { locale: { eq: $lang } } }) {
      nodes {
        title
        slug
        uri
        acf_schwerpunkt_card {
          textSchwerpunktTeaserText
          illustrationsvideo {
            mov {
              localFile {
                publicURL
              }
            }
            webm {
              localFile {
                publicURL
              }
            }
          }
        }
        acf_schwerpunkt_content {
          colorKartenFarbe
        }
      }
    }
    wpModeSettings(
      slug: { eq: "mobilitaetsschwerpunkte" }
      locale: { locale: { eq: $lang } }
    ) {
      acf_schwerpunkt_settings {
        textButtonToProjectgoals
        textButtonMore
        textTitleMobilitatsschwerpunkt
      }
    }
    projektzielSettings: wpModeSettings(
      slug: { eq: "projektziele" }
      locale: { locale: { eq: $lang } }
    ) {
      acf_projektziel_settings {
        textTitleButtonSchwerpunkte
      }
    }
  }
`
